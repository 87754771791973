var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bottom-bar",
      style: { backgroundColor: _vm.$themeTokens.textInverted },
    },
    [
      _vm._l(_vm.bottomMenuOptions[0].routes, function (routeDefinition, key) {
        return _c("span", { key: key }, [
          _c(
            "a",
            {
              staticClass: "nav-menu-item",
              style: { textDecoration: "none" },
              attrs: {
                href: _vm.generateNavRoute(
                  routeDefinition.route,
                  _vm.bottomMenuOptions[0].url
                ),
                tabindex: "-1",
              },
              on: {
                click: function ($event) {
                  return _vm.handleNav(
                    routeDefinition.route,
                    _vm.bottomMenuOptions[0].url
                  )
                },
              },
            },
            [
              _c(
                "div",
                {
                  style: _vm.isActiveLink(
                    routeDefinition.route,
                    _vm.bottomMenuOptions[0].url
                  )
                    ? _vm.bottomMenuActiveStyles
                    : _vm.bottomMenuInactiveStyles,
                },
                [
                  _c("KIconButton", {
                    attrs: {
                      icon: routeDefinition.icon,
                      color: _vm.isActiveLink(
                        routeDefinition.route,
                        _vm.bottomMenuOptions[0].url
                      )
                        ? _vm.$themeTokens.primary
                        : _vm.$themeTokens.annotation,
                      ariaLabel: routeDefinition.label,
                      size: "small",
                    },
                  }),
                ],
                1
              ),
              _vm.isActiveLink(
                routeDefinition.route,
                _vm.bottomMenuOptions[0].url
              )
                ? _c(
                    "p",
                    {
                      staticClass: "nav-menu-label",
                      style: { color: _vm.$themeTokens.primary },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(routeDefinition.label) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ])
      }),
      _c(
        "span",
        { staticClass: "nav-menu-item", style: _vm.bottomMenuInactiveStyles },
        [
          _c("KIconButton", {
            attrs: {
              icon: "menu",
              ariaLabel: _vm.coreString("menuLabel"),
              size: "small",
              color: _vm.navShown
                ? _vm.$themeTokens.primary
                : _vm.$themeTokens.annotation,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("toggleNav")
              },
            },
          }),
          _vm.navShown
            ? _c(
                "p",
                {
                  staticClass: "nav-menu-label",
                  style: { color: _vm.$themeTokens.primary },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.coreString("menuLabel")) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }