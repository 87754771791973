var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.viewAll
      ? _c("div", [_vm._t("default")], 2)
      : _c(
          "div",
          {
            ref: "content",
            staticClass: "truncated",
            style: { maxHeight: _vm.maxHeight + "px" },
          },
          [
            _vm.slotIsTruncated
              ? _c("div", {
                  staticClass: "overlay",
                  style: { height: _vm.maxHeight + "px" },
                })
              : _vm._e(),
            _vm._t("default"),
          ],
          2
        ),
    _vm.showViewMore && (_vm.slotIsTruncated || _vm.viewAll)
      ? _c(
          "div",
          { staticClass: "show-more" },
          [
            _c("KButton", {
              attrs: {
                appearance: "basic-link",
                text: _vm.viewAll
                  ? _vm.coreString("viewLessAction")
                  : _vm.coreString("viewMoreAction"),
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  _vm.viewAll = !_vm.viewAll
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }