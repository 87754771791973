var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "UiIcon",
        { ref: "icon" },
        [
          _vm.is(_vm.ContentNodeKinds.CHANNEL)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: { icon: _vm.ContentNodeKinds.CHANNEL, color: _vm.color },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.TOPIC)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: { icon: _vm.ContentNodeKinds.TOPIC, color: _vm.color },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.VIDEO)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: { icon: _vm.ContentNodeKinds.VIDEO, color: _vm.color },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.AUDIO)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: { icon: _vm.ContentNodeKinds.AUDIO, color: _vm.color },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.DOCUMENT)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: {
                  icon: _vm.ContentNodeKinds.DOCUMENT,
                  color: _vm.color,
                },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.EXERCISE)
            ? _c("KIcon", {
                class: [_vm.colorClass, { "rtl-icon": _vm.isRtl }],
                staticStyle: { top: "0" },
                attrs: {
                  icon: _vm.ContentNodeKinds.EXERCISE,
                  color: _vm.color,
                },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.HTML5)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: { icon: _vm.ContentNodeKinds.HTML5, color: _vm.color },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.EXAM)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: { icon: "quiz", color: _vm.color },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.LESSON)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: { icon: _vm.ContentNodeKinds.LESSON, color: _vm.color },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.ACTIVITY)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: {
                  icon: _vm.ContentNodeKinds.ACTIVITY.toLowerCase(),
                  color: _vm.color,
                },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.SLIDESHOW)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: {
                  icon: _vm.ContentNodeKinds.SLIDESHOW,
                  color: _vm.color,
                },
              })
            : _vm._e(),
          _vm.is(_vm.USER)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: { icon: _vm.USER, color: _vm.color },
              })
            : _vm._e(),
          _vm.is(_vm.ContentNodeKinds.CLASSROOM)
            ? _c("KIcon", {
                class: [_vm.colorClass],
                staticStyle: { top: "0" },
                attrs: { icon: "classes", color: _vm.color },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.tooltipText && _vm.showTooltip
        ? _c("KTooltip", { attrs: { reference: "icon", refs: _vm.$refs } }, [
            _vm._v("\n    " + _vm._s(_vm.tooltipText) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }