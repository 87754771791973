var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.typeDisplay
    ? _c("span", { staticClass: "user-type-display" }, [
        _vm._v("\n  " + _vm._s(_vm.typeDisplay) + "\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }