var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "attempt-box",
      style: {
        border:
          "2px solid " +
          (_vm.selected
            ? _vm.$themeTokens.text
            : _vm.$themeTokens.textDisabled),
        cursor: _vm.selected ? "auto" : "pointer",
      },
    },
    [
      _vm.isAnswer
        ? [
            _vm.interaction.correct
              ? _c("KIcon", {
                  staticClass: "svg-item",
                  style: [
                    _vm.svgItemBorder,
                    { fill: _vm.$themeTokens.correct },
                  ],
                  attrs: { icon: "correct" },
                })
              : _vm._e(),
            !_vm.interaction.correct
              ? _c("KIcon", {
                  staticClass: "svg-item",
                  style: [
                    _vm.svgItemBorder,
                    { fill: _vm.$themeTokens.incorrect },
                  ],
                  attrs: { icon: "incorrect" },
                })
              : _vm._e(),
          ]
        : _vm.isHint
        ? _c("KIcon", {
            staticClass: "svg-item",
            style: [_vm.svgItemBorder, { fill: _vm.$themeTokens.annotation }],
            attrs: { icon: "hint" },
          })
        : _vm.isError
        ? _c("KIcon", {
            staticClass: "svg-item",
            style: [_vm.svgItemBorder, { fill: _vm.$themeTokens.annotation }],
            attrs: { icon: "helpNeeded" },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }