var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bannerOpened
    ? _c(
        "div",
        {
          staticClass: "banner",
          style: { background: _vm.$themeTokens.surface },
        },
        [
          _c(
            "div",
            { staticClass: "banner-inner" },
            [
              _c("h1", { staticStyle: { display: "none" } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$tr("bannerHeading")) + "\n    "
                ),
              ]),
              _c(
                "KGrid",
                [
                  _c("KGridItem", { attrs: { layout12: { span: 12 } } }, [
                    _c("p", [
                      _vm._v(
                        "\n          " + _vm._s(_vm.getMessage()) + "\n        "
                      ),
                    ]),
                  ]),
                  _c(
                    "KGridItem",
                    {
                      staticClass: "button-grid-item",
                      attrs: { layout12: { span: 12 } },
                    },
                    [
                      _c("KButton", {
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          text: _vm.coreString("closeAction"),
                          appearance: "flat-button",
                          secondary: true,
                        },
                        on: { click: _vm.closeBanner },
                      }),
                      _vm.hasDownloads
                        ? _c("KButton", {
                            attrs: {
                              text: _vm.$tr("goToDownloads"),
                              appearance: "raised-button",
                              secondary: true,
                            },
                            on: { click: _vm.closeBanner },
                          })
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c("KButton", {
                            attrs: {
                              text: _vm.$tr("manageChannels"),
                              appearance: "raised-button",
                              secondary: true,
                            },
                            on: { click: _vm.manageChannel },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }