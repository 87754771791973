var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { backgroundColor: _vm.$themeTokens.surface } },
    [
      _c(
        "h3",
        { staticClass: "header", attrs: { id: "answer-history-label" } },
        [_vm._v("\n    " + _vm._s(_vm.$tr("answerHistoryLabel")) + "\n  ")]
      ),
      _vm.isMobile
        ? _c("KSelect", {
            staticClass: "history-select",
            attrs: {
              value: _vm.selected,
              "aria-labelledby": "answer-history-label",
              options: _vm.options,
              disabled: _vm.$attrs.disabled,
            },
            on: {
              change: function ($event) {
                return _vm.handleDropdownChange($event.value)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "display",
                  fn: function () {
                    return [
                      _c("AttemptLogItem", {
                        attrs: {
                          isSurvey: _vm.isSurvey,
                          attemptLog:
                            _vm.attemptLogs[_vm.selectedQuestionNumber],
                          displayTag: "span",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "option",
                  fn: function (ref) {
                    var index = ref.index
                    return [
                      _c("AttemptLogItem", {
                        attrs: {
                          isSurvey: _vm.isSurvey,
                          attemptLog: _vm.attemptLogs[index],
                          displayTag: "span",
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              2477778366
            ),
          })
        : _c(
            "ul",
            {
              ref: "attemptList",
              staticClass: "history-list",
              attrs: { role: "listbox" },
              on: {
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "home",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    return _vm.setSelectedAttemptLog(0)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "end",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    return _vm.setSelectedAttemptLog(_vm.attemptLogs.length - 1)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    _vm.setSelectedAttemptLog(
                      _vm.previousQuestion(_vm.selectedQuestionNumber)
                    )
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "left", 37, $event.key, [
                        "Left",
                        "ArrowLeft",
                      ])
                    ) {
                      return null
                    }
                    if ("button" in $event && $event.button !== 0) {
                      return null
                    }
                    $event.preventDefault()
                    _vm.setSelectedAttemptLog(
                      _vm.previousQuestion(_vm.selectedQuestionNumber)
                    )
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    _vm.setSelectedAttemptLog(
                      _vm.nextQuestion(_vm.selectedQuestionNumber)
                    )
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "right", 39, $event.key, [
                        "Right",
                        "ArrowRight",
                      ])
                    ) {
                      return null
                    }
                    if ("button" in $event && $event.button !== 2) {
                      return null
                    }
                    $event.preventDefault()
                    _vm.setSelectedAttemptLog(
                      _vm.nextQuestion(_vm.selectedQuestionNumber)
                    )
                  },
                ],
              },
            },
            [
              _vm._l(_vm.attemptLogs, function (attemptLog, index) {
                return [
                  _c(
                    "li",
                    {
                      key: index,
                      staticClass: "attempt-item",
                      style: {
                        backgroundColor: _vm.isSelected(index)
                          ? _vm.$themePalette.grey.v_100
                          : "",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          ref: "attemptListOption",
                          refInFor: true,
                          staticClass: "attempt-item-anchor",
                          attrs: {
                            role: "option",
                            "aria-selected": _vm.isSelected(index).toString(),
                            tabindex: _vm.isSelected(index) ? 0 : -1,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.setSelectedAttemptLog(index)
                            },
                            keydown: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.setSelectedAttemptLog(index)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                                return _vm.setSelectedAttemptLog(index)
                              },
                            ],
                          },
                        },
                        [
                          _c("AttemptLogItem", {
                            attrs: {
                              isSurvey: _vm.isSurvey,
                              attemptLog: attemptLog,
                              displayTag: "p",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }