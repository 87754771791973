var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isUserLoggedIn
    ? _c(
        "div",
        { ref: "icon", staticClass: "points-wrapper" },
        [
          _c(
            "div",
            {
              staticClass: "icon-wrapper",
              style: { backgroundColor: _vm.$themeTokens.surface },
            },
            [
              _c("KIcon", {
                attrs: {
                  icon: "pointsActive",
                  color: _vm.$themeTokens.primary,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "description" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$formatNumber(_vm.totalPoints)) + "\n  "
            ),
          ]),
          _c("KTooltip", { attrs: { reference: "icon", refs: _vm.$refs } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$tr("pointsTooltip", { points: _vm.totalPoints })) +
                "\n  "
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }