var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _vm.singleFacility && _vm.facility.name
        ? _c("p", { staticClass: "facility-name" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.formatNameAndId(_vm.facility.name, _vm.facility.id)
                ) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _c("p", [_vm._v("\n    " + _vm._s(_vm.prompt) + "\n  ")]),
      _vm.error
        ? _c("p", { style: { color: _vm.$themeTokens.error } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.coreString("invalidCredentialsError")) +
                "\n  "
            ),
          ])
        : _vm._e(),
      false
        ? _c("p", [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$tr("duplicateFacilityNamesExplanation")) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _c("UsernameTextbox", {
        ref: "username",
        attrs: {
          value: _vm.username,
          isValid: _vm.usernameValid,
          shouldValidate: _vm.shouldValidate,
          autofocus: true,
          disabled: _vm.$attrs.disabled,
        },
        on: {
          "update:value": function ($event) {
            _vm.username = $event
          },
          "update:isValid": function ($event) {
            _vm.usernameValid = $event
          },
          "update:is-valid": function ($event) {
            _vm.usernameValid = $event
          },
        },
      }),
      _c("PasswordTextbox", {
        ref: "password",
        attrs: {
          value: _vm.password,
          isValid: _vm.passwordValid,
          shouldValidate: _vm.shouldValidate,
          showConfirmationInput: false,
          disabled: _vm.$attrs.disabled,
        },
        on: {
          "update:value": function ($event) {
            _vm.password = $event
          },
          "update:isValid": function ($event) {
            _vm.passwordValid = $event
          },
          "update:is-valid": function ($event) {
            _vm.passwordValid = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }