var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c("div", {
            staticClass: "focus-trap-first",
            attrs: { tabindex: "0" },
            on: { focus: _vm.handleFirstTrapFocus },
          })
        : _vm._e(),
      _vm._t("default"),
      !_vm.disabled
        ? _c("div", {
            staticClass: "focus-trap-last",
            attrs: { tabindex: "0" },
            on: { focus: _vm.handleLastTrapFocus },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }