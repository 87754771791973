var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: { size: "large", title: _vm.coreString("usageAndPrivacyLabel") },
      on: {
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("KButton", {
                attrs: { text: _vm.coreString("closeAction"), primary: "" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("submit")
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.hideUsersSection
        ? _c(
            "section",
            [
              _c("h2", [_vm._v(_vm._s(_vm.coreString("usersLabel")))]),
              _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriUsersP1")))]),
              _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriUsersP2")))]),
              _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriUsersP3")))]),
              _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriUsersP4")))]),
              _c("ul", [
                _c("li", [_vm._v(_vm._s(_vm.$tr("kolibriUsersL1")))]),
                _c("li", [_vm._v(_vm._s(_vm.$tr("kolibriUsersL2")))]),
                _c("li", [_vm._v(_vm._s(_vm.$tr("kolibriUsersL3")))]),
                _c("li", [_vm._v(_vm._s(_vm.$tr("kolibriUsersL4")))]),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriUsersP5")))]),
              _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriUsersP6")))]),
              _vm.oidcProviderEnabled
                ? [
                    _c("h3", [_vm._v(_vm._s(_vm.$tr("openIdH1")))]),
                    _c("p", [_vm._v(_vm._s(_vm.$tr("openIdP1")))]),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      !_vm.hideOwnersSection
        ? _c("section", [
            _c("h2", [_vm._v(_vm._s(_vm.$tr("kolibriOwnersTitle")))]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriOwnersP1")))]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriOwnersP2")))]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriOwnersP3")))]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriOwnersP4")))]),
          ])
        : _vm._e(),
      !_vm.hideAboutSection
        ? _c("section", [
            _c("h2", [_vm._v(_vm._s(_vm.$tr("kolibriAboutTitle")))]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriAboutP1")))]),
            _c(
              "p",
              [
                _c("KExternalLink", {
                  attrs: {
                    text: "https://learningequality.org",
                    href: "https://learningequality.org",
                    openInNewTab: true,
                  },
                }),
              ],
              1
            ),
            _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriAboutP2")))]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriAboutP3")))]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriAboutP4")))]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("kolibriAboutP5")))]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }