var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: {
        title: _vm.getCommonSyncString("newAddressTitle"),
        submitText: _vm.$tr("submitButtonLabel"),
        cancelText: _vm.coreString("cancelAction"),
        size: "medium",
        submitDisabled: _vm.attemptingToConnect,
      },
      on: {
        submit: _vm.handleSubmit,
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
      },
    },
    [
      _c("p", [_vm._v(_vm._s(_vm.$tr("addressDesc")))]),
      _c(
        "div",
        [
          _c("KTextbox", {
            ref: "address",
            attrs: {
              label: _vm.$tr("addressLabel"),
              placeholder: _vm.$tr("addressPlaceholder"),
              autofocus: true,
              invalid: _vm.addressIsInvalid,
              invalidText: _vm.addressInvalidText,
              disabled: _vm.attemptingToConnect,
            },
            on: {
              blur: function ($event) {
                _vm.addressBlurred = true
              },
            },
            model: {
              value: _vm.address,
              callback: function ($$v) {
                _vm.address = $$v
              },
              expression: "address",
            },
          }),
        ],
        1
      ),
      _c("p", [_vm._v(_vm._s(_vm.$tr("nameDesc")))]),
      _c(
        "div",
        [
          _c("KTextbox", {
            attrs: {
              label: _vm.$tr("nameLabel"),
              placeholder: _vm.$tr("namePlaceholder"),
              invalid: _vm.nameIsInvalid,
              invalidText: _vm.coreString("requiredFieldError"),
              maxlength: 40,
              disabled: _vm.attemptingToConnect,
            },
            on: {
              blur: function ($event) {
                _vm.nameBlurred = true
              },
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
        ],
        1
      ),
      _vm.attemptingToConnect
        ? _c("UiAlert", { attrs: { dismissible: false } }, [
            _vm._v("\n    " + _vm._s(_vm.$tr("tryingToConnect")) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }