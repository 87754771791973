var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "span",
        { ref: "icon" },
        [
          _vm.isInProgress
            ? _c(
                "UiIcon",
                { attrs: { ariaLabel: _vm.coreString("inProgressLabel") } },
                [
                  _c("KIcon", {
                    staticClass: "icon",
                    style: _vm.iconStyle(_vm.$themeTokens.progress),
                    attrs: {
                      icon: "schedule",
                      color: _vm.$themeTokens.textInverted,
                    },
                  }),
                ],
                1
              )
            : _vm.isCompleted
            ? _c(
                "UiIcon",
                { attrs: { ariaLabel: _vm.coreString("completedLabel") } },
                [
                  _c("KIcon", {
                    staticClass: "icon",
                    style: _vm.iconStyle(_vm.$themeTokens.mastered),
                    attrs: {
                      icon: "star",
                      color: _vm.$themeTokens.textInverted,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("KTooltip", { attrs: { reference: "icon", refs: _vm.$refs } }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.isInProgress
                ? _vm.coreString("inProgressLabel")
                : _vm.coreString("completedLabel")
            ) +
            "\n  "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }