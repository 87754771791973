var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "k-filter" },
    [
      _c(
        "UiIcon",
        {
          staticClass: "k-filter-icon",
          style: { color: _vm.$themeTokens.annotation },
          attrs: { ariaLabel: _vm.coreString("filter") },
        },
        [_c("KIcon", { attrs: { icon: "search" } })],
        1
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.model,
            expression: "model",
            modifiers: { trim: true },
          },
        ],
        ref: "searchinput",
        class: [
          "k-filter-input",
          _vm.$computedClass(_vm.kFilterPlaceHolderStyle),
        ],
        style: {
          color: _vm.$themeTokens.text,
          border: "2px solid " + _vm.$themeTokens.fineLine,
        },
        attrs: {
          type: "search",
          placeholder: _vm.placeholder,
          "aria-label": _vm.placeholder,
          autofocus: _vm.autofocus,
        },
        domProps: { value: _vm.model },
        on: {
          keyup: function ($event) {
            return _vm.throttledEmitInput($event.target.value)
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.model = $event.target.value.trim()
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
      _c("KIconButton", {
        staticClass: "k-filter-clear-button",
        class: _vm.model === "" ? "" : "k-filter-clear-button-visible",
        attrs: {
          size: "small",
          icon: "clear",
          ariaLabel: _vm.coreString("clearAction"),
        },
        on: { click: _vm.handleClickClear },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }