var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.addingAddress
        ? _c("AddDeviceForm", {
            on: {
              cancel: _vm.goToSelectAddress,
              added_address: _vm.handleAddedAddress,
            },
          })
        : _c("SelectDeviceForm", {
            attrs: {
              filterByChannelId: _vm.filterByChannelId,
              filterByFacilityId: _vm.filterByFacilityId,
              filterLODAvailable: _vm.filterLODAvailable,
              filterByFacilityCanSignUp: _vm.filterByFacilityCanSignUp,
              filterByOnMyOwnFacility: _vm.filterByOnMyOwnFacility,
              selectedId: _vm.addedAddressId,
              formDisabled: _vm.$attrs.selectAddressDisabled,
            },
            on: {
              click_add_address: _vm.goToAddAddress,
              removed_address: _vm.handleRemovedAddress,
              cancel: _vm.handleCancel,
              submit: _vm.handleSelectAddressSubmit,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }