var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("KOptionalText", {
    attrs: {
      text: _vm.date
        ? _vm.$formatRelative(_vm.ceilingDate, { now: _vm.now })
        : "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }