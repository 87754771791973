var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "time-context" }, [
    _vm.seconds > 0
      ? _c(
          "div",
          [
            _vm._v(
              "\n    " +
                _vm._s(_vm.coreString("suggestedTimeToComplete")) +
                "\n    "
            ),
            _c("TimeDuration", { attrs: { seconds: _vm.seconds } }),
          ],
          1
        )
      : _vm._e(),
    _vm.seconds > 0
      ? _c(
          "div",
          [
            _c(
              "KLabeledIcon",
              { attrs: { icon: "schedule" } },
              [_c("TimeDuration", { attrs: { seconds: _vm.seconds } })],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }