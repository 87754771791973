var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pos-rel" },
    [
      _c("KSelect", {
        staticClass: "birthyear-select",
        attrs: {
          value: _vm.selected,
          label: _vm.coreString("birthYearLabel"),
          placeholder: _vm.$tr("placeholder"),
          options: _vm.options,
          disabled: _vm.$attrs.disabled,
        },
        on: {
          change: function ($event) {
            return _vm.$emit("update:value", $event.value)
          },
        },
      }),
      _c("CoreInfoIcon", {
        staticClass: "info-icon",
        attrs: {
          tooltipText: _vm.$tr("birthYearTooltip"),
          tooltipPlacement: _vm.tooltipPlacement,
          iconAriaLabel: _vm.$tr("birthyearAriaLabel"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }