var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "UiAlert",
        { staticClass: "alert", attrs: { dismissible: false, type: "error" } },
        [
          _c("span", [_vm._v(_vm._s(_vm.$tr("rendererNotAvailable")))]),
          _c("br"),
          _vm.error && _vm.error.message
            ? _c("KButton", {
                attrs: {
                  appearance: "basic-link",
                  text: _vm.appErrorTranslator.$tr("defaultErrorReportPrompt"),
                },
                on: {
                  click: function ($event) {
                    _vm.showDetailsModal = true
                  },
                },
              })
            : _vm._e(),
          _c("DownloadButton", {
            staticClass: "download-button",
            attrs: { files: _vm.files },
          }),
        ],
        1
      ),
      _vm.error && _vm.error.message && _vm.showDetailsModal
        ? _c("ReportErrorModal", {
            attrs: { error: _vm.error.message },
            on: {
              cancel: function ($event) {
                _vm.showDetailsModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }