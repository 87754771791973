var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "list-item-navigation visible" },
    [
      _c(
        "router-link",
        {
          staticClass: "tab",
          class: _vm.$computedClass(_vm.tabStyles),
          style: _vm.windowIsSmall ? _vm.smallScreenOverrides : {},
          attrs: { to: _vm.link, activeClass: _vm.activeClasses },
        },
        [
          _c(
            "div",
            { staticClass: "dimmable tab-icon" },
            [_vm._t("default")],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dimmable tab-title",
              attrs: { tabindex: "-1", title: _vm.title },
            },
            [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }