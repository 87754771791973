var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OnboardingForm",
    {
      attrs: { header: _vm.$tr("gettingStartedHeader") },
      scopedSlots: _vm._u([
        {
          key: "buttons",
          fn: function () {
            return [
              _c("KButton", {
                staticClass: "left-button",
                attrs: {
                  text: _vm.$tr("configureFacilityAction"),
                  appearance: "raised-button",
                  primary: "",
                },
                on: { click: _vm.goToPublicSetup },
              }),
              _c("KButton", {
                attrs: {
                  text: _vm.$tr("skipAction"),
                  appearance: "flat-button",
                },
                on: { click: _vm.goToPersonalSetup },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", { staticClass: "p1" }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("descriptionParagraph1")) + "\n  "),
      ]),
      _c("p", { staticClass: "p2" }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("descriptionParagraph2")) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }