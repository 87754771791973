var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: {
        title: _vm.$tr("registerFacility"),
        submitText: _vm.registerText,
        cancelText: _vm.cancelText,
      },
      on: { submit: _vm.registerFacility, cancel: _vm.cancel },
    },
    [
      !_vm.alreadyRegistered
        ? [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$tr("registerWith", { name: _vm.projectName }))
              ),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.$tr("dataSaved")))]),
          ]
        : [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$tr("alreadyRegistered", { name: _vm.projectName })
                ) +
                "\n  "
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }