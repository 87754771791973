var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("KButton", {
        attrs: {
          text: _vm.text || _vm.coreString("usageAndPrivacyLabel"),
          appearance: "basic-link",
        },
        on: {
          click: function ($event) {
            _vm.privacyModalVisible = true
          },
        },
      }),
      _vm.privacyModalVisible
        ? _c(
            "PrivacyInfoModal",
            _vm._b(
              {
                on: {
                  submit: function ($event) {
                    _vm.privacyModalVisible = false
                  },
                  cancel: function ($event) {
                    _vm.privacyModalVisible = false
                  },
                },
              },
              "PrivacyInfoModal",
              _vm.modalProps,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }