var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("KTextbox", {
    ref: "textbox",
    attrs: {
      value: _vm.value,
      disabled: _vm.$attrs.disabled,
      label: _vm.coreString("usernameLabel"),
      autofocus: _vm.$attrs.autofocus,
      maxlength: 30,
      invalid: Boolean(_vm.shownInvalidText),
      invalidText: _vm.shownInvalidText,
      autocomplete: "username",
    },
    on: {
      blur: function ($event) {
        _vm.blurred = true
      },
      input: _vm.handleInput,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }