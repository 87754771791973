var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MultiPaneLayout", {
    ref: "multiPaneLayout",
    staticClass: "container",
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "KGrid",
                {
                  staticClass: "page-status",
                  style: { backgroundColor: _vm.$themeTokens.surface },
                },
                [
                  _vm.windowIsSmall
                    ? _c(
                        "KGridItem",
                        { attrs: { layout4: { span: 4, alignment: "right" } } },
                        [_vm._t("actions")],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "KGridItem",
                    {
                      attrs: {
                        layout12: { span: 9, alignment: "left" },
                        layout8: { span: 5, alignment: "left" },
                        layout4: { span: 4, alignment: "left" },
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm.userId
                            ? _c(
                                "h1",
                                { staticClass: "title" },
                                [
                                  _c("KLabeledIcon", {
                                    attrs: {
                                      icon: "person",
                                      label: _vm.userName,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("KLabeledIcon", {
                            attrs: { icon: _vm.titleIcon, label: _vm.title },
                          }),
                        ],
                        1
                      ),
                      _vm.pastTries.length > 1 && !_vm.isSurvey
                        ? _c("TriesOverview", {
                            attrs: {
                              pastTries: _vm.pastTries,
                              totalQuestions: _vm.questions.length,
                              suggestedTime: _vm.duration,
                              isSurvey: _vm.isSurvey,
                            },
                          })
                        : _vm.currentTry
                        ? _c("CurrentTryOverview", {
                            attrs: {
                              userId: _vm.userId,
                              currentTry: _vm.currentTry,
                              totalQuestions: _vm.questions.length,
                              isSurvey: _vm.isSurvey,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.windowIsSmall
                    ? _c(
                        "KGridItem",
                        {
                          attrs: {
                            layout12: { span: 3, alignment: "right" },
                            layout8: { span: 3, alignment: "right" },
                            layout: { span: 2, alignment: "right" },
                          },
                        },
                        [_vm._t("actions")],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        !_vm.loading
          ? {
              key: "subheader",
              fn: function () {
                return [
                  _vm.pastTries.length > 1
                    ? _c("KSelect", {
                        staticClass: "try-selection",
                        style: { background: _vm.$themePalette.grey.v_100 },
                        attrs: {
                          value: _vm.pastTriesOptions[_vm.tryIndex],
                          label: _vm.$tr("attemptDropdownLabel"),
                          options: _vm.pastTriesOptions,
                          appearance: "flat-button",
                        },
                        on: { change: _vm.navigateToTry },
                      })
                    : _vm._e(),
                  _vm.currentTry &&
                  _vm.pastTries.length > 1 &&
                  _vm.currentTry.attemptlogs.length
                    ? _c("CurrentTryOverview", {
                        attrs: {
                          userId: _vm.userId,
                          currentTry: _vm.currentTry,
                          totalQuestions: _vm.questions.length,
                          hideStatus: true,
                          isSurvey: _vm.isSurvey,
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            }
          : null,
        !_vm.windowIsSmall &&
        !_vm.loading &&
        _vm.currentTry &&
        _vm.currentTry.attemptlogs.length
          ? {
              key: "aside",
              fn: function () {
                return [
                  _c("AttemptLogList", {
                    attrs: {
                      attemptLogs: _vm.attemptLogs,
                      selectedQuestionNumber: _vm.questionNumber,
                      isSurvey: _vm.isSurvey,
                    },
                    on: { select: _vm.navigateToQuestion },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
        _vm.currentTry && _vm.currentTry.attemptlogs.length
          ? {
              key: "main",
              fn: function () {
                return [
                  _vm.loading
                    ? _c("KCircularLoader", { staticClass: "loader" })
                    : _vm.itemId
                    ? [
                        _vm.windowIsSmall
                          ? _c("AttemptLogList", {
                              staticClass: "mobile-attempt-log-list",
                              attrs: {
                                isMobile: true,
                                attemptLogs: _vm.attemptLogs,
                                selectedQuestionNumber: _vm.questionNumber,
                                isSurvey: _vm.isSurvey,
                              },
                              on: { select: _vm.navigateToQuestion },
                            })
                          : _vm._e(),
                        _vm.exercise && _vm.exercise.available
                          ? _c(
                              "div",
                              {
                                staticClass: "exercise-container",
                                class: _vm.windowIsSmall
                                  ? "mobile-exercise-container"
                                  : "",
                                style: {
                                  backgroundColor: _vm.$themeTokens.surface,
                                },
                              },
                              [
                                _c("h3", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.coreString("questionNumberLabel", {
                                        questionNumber: _vm.questionNumber + 1,
                                      })
                                    )
                                  ),
                                ]),
                                !_vm.isSurvey
                                  ? _c(
                                      "div",
                                      {
                                        attrs: { "data-test": "diff-business" },
                                      },
                                      [
                                        _c("KCheckbox", {
                                          attrs: {
                                            label: _vm.coreString(
                                              "showCorrectAnswerLabel"
                                            ),
                                            checked: _vm.showCorrectAnswer,
                                          },
                                          on: {
                                            change: _vm.toggleShowCorrectAnswer,
                                          },
                                        }),
                                        _vm.currentAttemptDiff
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "padding-bottom": "15px",
                                                },
                                              },
                                              [
                                                _c("AttemptIconDiff", {
                                                  attrs: {
                                                    correct:
                                                      _vm.currentAttempt
                                                        .correct,
                                                    diff: _vm.currentAttemptDiff
                                                      .correct,
                                                  },
                                                }),
                                                _c("AttemptTextDiff", {
                                                  attrs: {
                                                    userId: _vm.userId,
                                                    correct:
                                                      _vm.currentAttempt
                                                        .correct,
                                                    diff: _vm.currentAttemptDiff
                                                      .correct,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.showCorrectAnswer
                                          ? _c("InteractionList", {
                                              attrs: {
                                                interactions:
                                                  _vm.currentInteractionHistory,
                                                selectedInteractionIndex:
                                                  _vm.selectedInteractionIndex,
                                              },
                                              on: {
                                                select:
                                                  _vm.navigateToQuestionAttempt,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("KContentRenderer", {
                                  attrs: {
                                    itemId: _vm.renderableItemId,
                                    allowHints: false,
                                    kind: _vm.exercise.kind,
                                    files: _vm.exercise.files,
                                    available: _vm.exercise.available,
                                    extraFields: _vm.exercise.extra_fields,
                                    interactive: false,
                                    assessment: true,
                                    answerState: _vm.answerState,
                                    showCorrectAnswer: _vm.showCorrectAnswer,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("MissingResourceAlert", {
                              attrs: { multiple: false },
                            }),
                      ]
                    : _c("p", [
                        _vm._v(
                          "\n      " + _vm._s(_vm.$tr("noItemId")) + "\n    "
                        ),
                      ]),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }