var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("KSelect", {
    attrs: {
      value: _vm.selected,
      label: _vm.coreString("genderLabel"),
      placeholder: _vm.$tr("placeholder"),
      options: _vm.options,
      disabled: _vm.$attrs.disabled,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("update:value", $event.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }