var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "ul",
        { staticClass: "ui-menu", class: _vm.classes, attrs: { role: "menu" } },
        [
          _vm.$slots.header
            ? _c(
                "div",
                {
                  staticClass: "ui-menu-header",
                  style: { color: _vm.$themeTokens.text },
                },
                [_vm._t("header")],
                2
              )
            : _vm._e(),
          _c(
            "FocusTrap",
            {
              ref: "focusTrap",
              staticClass: "ui-menu-options",
              attrs: { disabled: !_vm.containFocus },
              on: {
                shouldFocusFirstEl: function ($event) {
                  return _vm.$emit("shouldFocusFirstEl")
                },
                shouldFocusLastEl: _vm.focusLastEl,
              },
            },
            [_vm._t("options")],
            2
          ),
          _vm.$slots.footer
            ? _c(
                "div",
                {
                  staticClass: "ui-menu-footer",
                  style: { color: _vm.$themeTokens.text },
                },
                [_vm._t("footer")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }