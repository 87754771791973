var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading && _vm.notAuthorized
        ? _c(
            "AppBarPage",
            [
              _c(
                "KPageContainer",
                [
                  _c("AuthMessage", {
                    attrs: {
                      authorizedRole: _vm.authorizedRole,
                      header: _vm.authorizationErrorHeader,
                      details: _vm.authorizationErrorDetails,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : !_vm.loading && _vm.error
        ? _c("AppBarPage", [_c("KPageContainer", [_c("AppError")], 1)], 1)
        : _c(
            "div",
            { attrs: { tabindex: "-1", "data-test": "base-page" } },
            [_vm._t("default", null, { loading: _vm.loading })],
            2
          ),
      _c("GlobalSnackbar"),
      !_vm.loading && _vm.showNotification && _vm.mostRecentNotification
        ? _c("UpdateNotification", {
            attrs: {
              id: _vm.mostRecentNotification.id,
              title: _vm.mostRecentNotification.title,
              msg: _vm.mostRecentNotification.msg,
              linkText: _vm.mostRecentNotification.linkText,
              linkUrl: _vm.mostRecentNotification.linkUrl,
            },
            on: { submit: _vm.dismissUpdateModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }