var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.subRoutes && _vm.subRoutes.length > 0
      ? _c("li", [
          _c(
            "a",
            {
              ref: "menuItem",
              staticClass: "core-menu-option",
              class: _vm.$computedClass(_vm.optionStyle),
              attrs: { role: "menuitem", tabindex: "0" },
              on: {
                click: function ($event) {
                  _vm.visibleSubMenu = !_vm.visibleSubMenu
                },
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  _vm.visibleSubMenu = !_vm.visibleSubMenu
                },
              },
            },
            [
              _vm._t("default", function () {
                return [
                  _c(
                    "KLabeledIcon",
                    {
                      attrs: { iconAfter: _vm.iconAfter },
                      scopedSlots: _vm._u(
                        [
                          _vm.icon
                            ? {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c("KIcon", {
                                      attrs: {
                                        icon: _vm.icon,
                                        color: _vm.optionIconColor,
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm.label
                        ? _c("div", [_vm._v(_vm._s(_vm.label))])
                        : _vm._e(),
                    ]
                  ),
                  _vm.secondaryText
                    ? _c("div", [_vm._v(_vm._s(_vm.secondaryText))])
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ])
      : _c("li", [
          _c(
            "a",
            {
              ref: "menuItem",
              staticClass: "core-menu-option",
              class: _vm.$computedClass(_vm.optionStyle),
              attrs: { href: _vm.link, role: "menuitem", tabindex: "0" },
              on: {
                click: _vm.conditionalEmit,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.conditionalEmit.apply(null, arguments)
                },
              },
            },
            [
              _vm._t("default", function () {
                return [
                  _c(
                    "KLabeledIcon",
                    {
                      scopedSlots: _vm._u(
                        [
                          _vm.icon
                            ? {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c("KIcon", {
                                      attrs: {
                                        icon: _vm.icon,
                                        color: _vm.optionIconColor,
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm.label
                        ? _c("div", [_vm._v(_vm._s(_vm.label))])
                        : _vm._e(),
                    ]
                  ),
                  _vm.secondaryText
                    ? _c("div", [_vm._v(_vm._s(_vm.secondaryText))])
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
    _vm.visibleSubMenu
      ? _c(
          "div",
          _vm._l(_vm.subRoutes, function (subRoute) {
            return _c("div", { key: subRoute.label }, [
              _c(
                "div",
                { staticClass: "link-container" },
                [
                  _vm.linkActive
                    ? _c("router-link", {
                        attrs: {
                          to: {
                            name: subRoute.name,
                            params: _vm.$route.params,
                            query: _vm.$router.query,
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var href = ref.href
                                var navigate = ref.navigate
                                var isActive = ref.isActive
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      class: isActive
                                        ? _vm.subRouteActiveClass
                                        : _vm.subRouteInactiveClass,
                                      attrs: { href: href },
                                      on: {
                                        click: function (e) {
                                          return isActive
                                            ? _vm.toggleMenu()
                                            : _vm.toggleMenu() && navigate(e)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(subRoute.label) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _c(
                        "a",
                        {
                          staticClass: "link",
                          class: _vm.subRouteInactiveClass,
                          attrs: { href: _vm.generateNavRoute(subRoute.route) },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(subRoute.label) +
                              "\n        "
                          ),
                        ]
                      ),
                ],
                1
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }