var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sort-widget",
      class: { focused: _vm.hasFocus, "not-focused": !_vm.hasFocus },
    },
    [
      _c("KIconButton", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isFirst,
            expression: "!isFirst",
          },
        ],
        ref: "upBtn",
        staticClass: "btn up",
        class: { visuallyhidden: !_vm.hasFocus },
        attrs: { icon: "chevronUp", size: "mini", ariaLabel: _vm.moveUpText },
        on: {
          click: _vm.clickUp,
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            return _vm.clickUp.apply(null, arguments)
          },
        },
      }),
      _c("KIcon", {
        staticClass: "grip",
        staticStyle: { top: "0", width: "24px", height: "24px" },
        attrs: { icon: "dragHorizontal" },
      }),
      _c("KIconButton", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLast,
            expression: "!isLast",
          },
        ],
        ref: "dnBtn",
        staticClass: "btn dn",
        class: { visuallyhidden: !_vm.hasFocus },
        attrs: {
          icon: "chevronDown",
          size: "mini",
          ariaLabel: _vm.moveDownText,
        },
        on: {
          click: _vm.clickDown,
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            return _vm.clickDown.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }