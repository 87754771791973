var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.correct && _vm.diff >= 1
    ? _c("KIcon", {
        style: { fill: _vm.$themeTokens.correct },
        attrs: { icon: "plus" },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }