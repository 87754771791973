var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "scores" }, [
    _c("tr", [
      _c("th", [
        _vm._v("\n      " + _vm._s(_vm.coreString("statusLabel")) + "\n    "),
      ]),
      _c(
        "td",
        [
          _c("ProgressIcon", {
            staticClass: "svg-icon",
            attrs: { progress: _vm.progress },
          }),
          _vm.complete
            ? [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.coreString("completedLabel")) +
                    "\n      "
                ),
              ]
            : _vm.progress
            ? [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.coreString("inProgressLabel")) +
                    "\n      "
                ),
              ]
            : [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.coreString("notStartedLabel")) +
                    "\n      "
                ),
              ],
        ],
        2
      ),
    ]),
    _c("tr", [
      _c("th", [
        _vm._v("\n      " + _vm._s(_vm.$tr("bestScoreLabel")) + "\n    "),
      ]),
      _c("td", [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$formatNumber(_vm.bestScore, { style: "percent" })) +
            "\n    "
        ),
      ]),
    ]),
    _c("tr", [
      _c("th", [
        _vm._v(
          "\n      " +
            _vm._s(_vm.coreString("questionsCorrectLabel")) +
            "\n    "
        ),
      ]),
      _c("td", [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.coreString("questionsCorrectValue", {
                correct: _vm.maxQuestionsCorrect,
                total: _vm.totalQuestions,
              })
            ) +
            "\n    "
        ),
      ]),
    ]),
    _vm.bestTimeSpent !== null
      ? _c("tr", [
          _c("th", [
            _vm._v(
              "\n      " + _vm._s(_vm.$tr("bestScoreTimeLabel")) + "\n    "
            ),
          ]),
          _c(
            "td",
            [
              _c("TimeDuration", { attrs: { seconds: _vm.bestTimeSpent } }),
              _c("br"),
              _vm.suggestedTimeAnnotation
                ? _c(
                    "span",
                    {
                      staticClass: "try-annotation",
                      style: { color: _vm.$themeTokens.annotation },
                    },
                    [_vm._v(_vm._s(_vm.suggestedTimeAnnotation))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }