var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "pos-rel" },
    [
      _c(
        "UiIcon",
        {
          ref: "icon",
          staticClass: "info-icon",
          style: { color: _vm.$themeTokens.primary },
          attrs: { iconAriaLabel: _vm.iconAriaLabel },
        },
        [
          _c("KIcon", {
            style: { fill: _vm.$themeTokens.primary },
            attrs: { icon: "info" },
          }),
        ],
        1
      ),
      _c(
        "KTooltip",
        {
          class: { ltr: !_vm.isRtl },
          attrs: {
            reference: "icon",
            refs: _vm.$refs,
            placement: _vm.tooltipPlacement,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.tooltipText) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }