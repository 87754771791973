var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "KGrid",
        [
          _c(
            "KGridItem",
            { attrs: { layout12: { span: 7 } } },
            [_vm._t("otherFilter")],
            2
          ),
          _c(
            "KGridItem",
            {
              staticClass: "text-filter",
              attrs: { layout12: { span: 5, alignment: "right" } },
            },
            [
              _c("FilterTextbox", {
                attrs: { placeholder: _vm.filterPlaceholder },
                model: {
                  value: _vm.filterInput,
                  callback: function ($$v) {
                    _vm.filterInput = $$v
                  },
                  expression: "filterInput",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm._t("default", null, null, {
            items: _vm.visibleFilteredItems,
            filterInput: _vm.filterInput,
          }),
        ],
        2
      ),
      _c(
        "nav",
        { staticClass: "pagination-nav" },
        [
          _c(
            "span",
            { staticClass: "pagination-label", attrs: { dir: "auto" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$tr("pagination", {
                      visibleStartRange: _vm.visibleStartRange,
                      visibleEndRange: _vm.visibleEndRange,
                      numFilteredItems: _vm.numFilteredItems,
                    })
                  ) +
                  "\n    "
              ),
            ]
          ),
          _c(
            "KButtonGroup",
            [
              _c("KIconButton", {
                attrs: {
                  ariaLabel: _vm.$tr("previousResults"),
                  disabled: _vm.previousButtonDisabled,
                  size: "small",
                  icon: "back",
                },
                on: {
                  click: function ($event) {
                    return _vm.changePage(-1)
                  },
                },
              }),
              _c("KIconButton", {
                attrs: {
                  ariaLabel: _vm.$tr("nextResults"),
                  disabled: _vm.nextButtonDisabled,
                  size: "small",
                  icon: "forward",
                },
                on: {
                  click: function ($event) {
                    return _vm.changePage(+1)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }