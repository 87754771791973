var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-message" }, [
    _c("h1", [_vm._v("\n    " + _vm._s(_vm.header) + "\n  ")]),
    _c(
      "p",
      [
        _vm._t("details", function () {
          return [_vm._v("\n      " + _vm._s(_vm.detailsText) + "\n    ")]
        }),
      ],
      2
    ),
    !_vm.isUserLoggedIn
      ? _c(
          "p",
          [
            _c("KExternalLink", {
              attrs: {
                text: _vm.linkText,
                href: _vm.signInLink,
                appearance: "basic-link",
              },
            }),
          ],
          1
        )
      : _c(
          "p",
          [
            _c("KRouterLink", {
              attrs: {
                text: _vm.$tr("goBackToHomeAction"),
                to: { path: "/" },
                appearance: "basic-link",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }