var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("textarea", {
      staticClass: "error-log",
      style: [
        _vm.dynamicHeightStyle,
        {
          backgroundColor: _vm.$themePalette.grey.v_50,
          border: _vm.$themePalette.grey.v_400,
        },
      ],
      attrs: { readonly: "", wrap: "soft", "aria-hidden": "true" },
      domProps: { value: _vm.text },
    }),
    _c("pre", { ref: "textBox", staticClass: "visuallyhidden" }, [
      _vm._v(_vm._s(_vm.text)),
    ]),
    _c(
      "div",
      [
        _vm.clipboardCapable
          ? _c("KButton", {
              ref: "copyButton",
              style: { marginTop: "8px", marginBottom: "8px" },
              attrs: {
                primary: false,
                text: _vm.$tr("copyToClipboardButtonPrompt"),
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }