var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FacilityTaskPanelDetails", {
    attrs: {
      statusMsg: _vm.taskInfo.statusMsg,
      headingMsg: _vm.taskInfo.headingMsg,
      underHeadingMsg: _vm.taskInfo.deviceNameMsg,
      underProgressMsg: _vm.taskInfo.bytesTransferredMsg,
      task: _vm.task,
      loaderType: _vm.loaderType,
      showCircularLoader: _vm.taskInfo.isRunning,
      buttonSet: _vm.buttonSet,
    },
    on: {
      cancel: function ($event) {
        return _vm.$emit("cancel")
      },
      clear: function ($event) {
        return _vm.$emit("clear")
      },
      retry: function ($event) {
        return _vm.$emit("retry")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }