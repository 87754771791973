var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.atSelectSource
        ? _c("SelectSyncSourceModal", {
            attrs: { formDisabled: _vm.syncSubmitDisabled },
            on: {
              submit: _vm.handleSourceSubmit,
              cancel: function ($event) {
                return _vm.closeModal()
              },
            },
          })
        : _vm.atSelectAddress
        ? _c("SelectDeviceModalGroup", {
            attrs: {
              filterByFacilityId: _vm.facilityForSync.id,
              selectAddressDisabled: _vm.syncSubmitDisabled,
            },
            on: {
              submit: _vm.handleAddressSubmit,
              cancel: function ($event) {
                return _vm.closeModal()
              },
            },
          })
        : _vm.atRegister
        ? _c("RegisterFacilityModal", {
            attrs: { displaySkipOption: true },
            on: {
              success: _vm.handleValidateSuccess,
              cancel: _vm.closeModal,
              skip: _vm.emitKdpSync,
            },
          })
        : _vm.atConfirmation
        ? _c("ConfirmationRegisterModal", {
            attrs: {
              targetFacility: _vm.facilityForSync,
              projectName: _vm.kdpProject.name,
              token: _vm.kdpProject.token,
              successOnAlreadyRegistered: true,
            },
            on: { success: _vm.emitKdpSync, cancel: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }