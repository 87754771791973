var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password-textbox" },
    [
      _c(
        "KTextbox",
        _vm._b(
          {
            ref: "password",
            attrs: {
              value: _vm.value,
              type: "password",
              label: _vm.coreString("passwordLabel"),
              invalid: Boolean(_vm.shownPasswordInvalidText),
              invalidText: _vm.shownPasswordInvalidText,
            },
            on: {
              blur: function ($event) {
                _vm.passwordBlurred = true
              },
              input: function ($event) {
                return _vm.$emit("update:value", $event)
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.checkErrorsAndSubmit.apply(null, arguments)
              },
            },
          },
          "KTextbox",
          _vm.$attrs,
          false
        )
      ),
      _vm.showConfirmationInput
        ? _c("KTextbox", {
            ref: "confirm",
            attrs: {
              type: "password",
              disabled: _vm.$attrs.disabled,
              label: _vm.$tr("confirmPasswordLabel"),
              invalid: Boolean(_vm.shownConfirmationInvalidText),
              invalidText: _vm.shownConfirmationInvalidText,
              autocomplete: _vm.$attrs.autocomplete,
            },
            on: {
              blur: function ($event) {
                _vm.confirmationBlurred = true
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.checkErrorsAndSubmit.apply(null, arguments)
              },
            },
            model: {
              value: _vm.confirmation,
              callback: function ($$v) {
                _vm.confirmation = $$v
              },
              expression: "confirmation",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }