var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "onboarding-form-header" }, [
      _vm._v("\n    " + _vm._s(_vm.header) + "\n  "),
    ]),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.$emit("submit")
          },
        },
      },
      [
        _c(
          "fieldset",
          { staticClass: "onboarding-form-fields" },
          [
            _c("legend", { staticClass: "onboarding-form-legend" }, [
              _vm.hasDescription
                ? _c(
                    "span",
                    { staticClass: "onboardng-form-description" },
                    [
                      _vm._t("description", function () {
                        return [_vm._v(_vm._s(_vm.description))]
                      }),
                    ],
                    2
                  )
                : _c("span", { staticClass: "visuallyhidden" }, [
                    _vm._v(_vm._s(_vm.header)),
                  ]),
            ]),
            _vm._t("default"),
          ],
          2
        ),
        _c("div", { staticClass: "form-footer" }, [_vm._t("footer")], 2),
        _vm._t("buttons", function () {
          return [
            _c(
              "KButtonGroup",
              [
                _c("KButton", {
                  staticClass: "onboarding-form-submit",
                  attrs: {
                    primary: true,
                    type: "submit",
                    text: _vm.submitText || _vm.coreString("continueAction"),
                    disabled: _vm.$attrs.disabled,
                  },
                }),
                _vm.finishButton
                  ? _c("KButton", {
                      attrs: { text: _vm.coreString("finishAction") },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("click_finish")
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }