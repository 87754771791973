var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { marginBottom: "8px" } },
    [
      _c(
        "UiAlert",
        {
          style: { marginBottom: 0, marginTop: "8px" },
          attrs: { dismissible: false, removeIcon: true, type: "warning" },
        },
        [
          _vm.$slots.syncAlert
            ? _c("span", [_vm._t("syncAlert")], 2)
            : _c("span", [
                _c(
                  "span",
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.coreString(
                            _vm.multiple
                              ? "someResourcesMissingOrNotSupported"
                              : "resourceNotFoundOnDevice"
                          )
                        ) +
                        "\n       \n        "
                    ),
                    _c(
                      "KButton",
                      {
                        attrs: { appearance: "basic-link" },
                        on: {
                          click: function ($event) {
                            _vm.open = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("learnMore")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
        ]
      ),
      _vm.open
        ? _c(
            "KModal",
            {
              attrs: {
                title: _vm.$tr("resourcesUnavailableTitle"),
                cancelText: _vm.coreString("closeAction"),
              },
              on: {
                cancel: function ($event) {
                  _vm.open = false
                },
              },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.$tr("resourcesUnavailableP1")))]),
              _c("p", [_vm._v(_vm._s(_vm.$tr("resourcesUnavailableP2")))]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }