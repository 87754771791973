var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "task-panel",
      class: { "task-panel-sm": _vm.windowIsSmall },
    },
    [
      _c(
        "div",
        { staticClass: "icon" },
        [
          _c(
            "transition",
            { attrs: { mode: "out-in" } },
            [
              _vm.showCircularLoader || _vm.taskIsCanceling
                ? _c("KCircularLoader", { attrs: { size: 24, stroke: 5 } })
                : _vm.taskIsFailed
                ? _c("KIcon", {
                    style: { fill: _vm.$themeTokens.error },
                    attrs: { icon: "helpNeeded" },
                  })
                : _vm.taskIsCompleted
                ? _c("KIcon", {
                    style: { fill: _vm.$themeTokens.success },
                    attrs: { icon: "done" },
                  })
                : _c("KIcon", {
                    style: { fill: _vm.$themeTokens.annotation },
                    attrs: { icon: "inProgress" },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "details" }, [
        _c(
          "p",
          {
            staticClass: "details-status",
            style: { color: _vm.$themeTokens.annotation },
          },
          [_vm._v("\n      " + _vm._s(_vm.statusMsg) + "\n    ")]
        ),
        _vm.headingMsg
          ? _c("h2", { staticClass: "details-description" }, [
              _vm._v("\n      " + _vm._s(_vm.headingMsg) + "\n    "),
            ])
          : _vm._e(),
        _vm.underHeadingMsg
          ? _c("p", { staticClass: "fs0" }, [
              _vm._v("\n      " + _vm._s(_vm.underHeadingMsg) + "\n    "),
            ])
          : _vm._e(),
        _vm.loaderType && !_vm.statusHidesLoader
          ? _c(
              "div",
              { staticClass: "details-progress-bar" },
              [
                _vm.loaderType === "determinate"
                  ? [
                      _c("KLinearLoader", {
                        staticClass: "k-linear-loader",
                        style: { backgroundColor: _vm.$themeTokens.fineLine },
                        attrs: {
                          type: "determinate",
                          delay: false,
                          progress: _vm.task.percentage * 100,
                        },
                      }),
                      _vm.taskPercentage
                        ? _c("span", { staticClass: "details-percentage" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$formatNumber(_vm.taskPercentage, {
                                    style: "percent",
                                  })
                                ) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  : _vm.loaderType === "indeterminate"
                  ? [
                      _c("KLinearLoader", {
                        staticClass: "k-linear-loader",
                        style: { backgroundColor: _vm.$themeTokens.fineLine },
                        attrs: { type: "indeterminate", delay: false },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm.underProgressMsg
          ? _c("p", { staticClass: "fs0" }, [
              _vm._v("\n      " + _vm._s(_vm.underProgressMsg) + "\n    "),
            ])
          : _vm._e(),
        _vm.startedByMsg
          ? _c(
              "p",
              {
                staticClass: "fs0",
                style: { color: _vm.$themeTokens.annotation },
              },
              [_vm._v("\n      " + _vm._s(_vm.startedByMsg) + "\n    ")]
            )
          : _vm._e(),
      ]),
      _vm.buttonSet
        ? _c(
            "KButtonGroup",
            {
              staticClass: "nowrap",
              class: { "button-lift": Boolean(_vm.loaderType) },
            },
            [
              _vm.buttonSet === "cancel"
                ? _c("KButton", {
                    attrs: {
                      disabled: _vm.taskIsCanceling,
                      text: _vm.coreString("cancelAction"),
                      appearance: "flat-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("cancel")
                      },
                    },
                  })
                : _vm._e(),
              _vm.buttonSet === "clear" || _vm.buttonSet === "retry"
                ? _c("KButton", {
                    attrs: {
                      text: _vm.coreString("clearAction"),
                      appearance: "flat-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("clear")
                      },
                    },
                  })
                : _vm._e(),
              _vm.buttonSet === "retry"
                ? _c("KButton", {
                    attrs: {
                      text: _vm.coreString("retryAction"),
                      appearance: "raised-button",
                      primary: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("retry")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }