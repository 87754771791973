var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.facility
    ? _c("div", [
        _c("div", [
          _c(
            "h2",
            { staticClass: "name" },
            [
              _c(
                "KLabeledIcon",
                {
                  attrs: { icon: "facility" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "iconAfter",
                        fn: function () {
                          return [
                            _vm.facility.dataset.registered
                              ? _c("KIcon", {
                                  ref: "icon",
                                  style: { fill: _vm.$themeTokens.success },
                                  attrs: { icon: "registered" },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3539973543
                  ),
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.facility.name) + "\n        "
                  ),
                ]
              ),
              _c(
                "KTooltip",
                { attrs: { reference: "icon", refs: _vm.$refs } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tr("registeredAlready")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", [
          _c(
            "span",
            [
              _vm.facility.syncing || _vm.isSyncing
                ? [
                    _c("KCircularLoader", {
                      staticClass: "loader",
                      attrs: { size: 16, delay: false },
                    }),
                    _vm._v(
                      "\n        " + _vm._s(_vm.$tr("syncing")) + "\n      "
                    ),
                  ]
                : _vm.isDeleting
                ? [
                    _c("KCircularLoader", {
                      staticClass: "loader",
                      attrs: { size: 16, delay: false },
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getTaskString("removingFacilityStatus")) +
                        "\n      "
                    ),
                  ]
                : [
                    _vm.syncFailed
                      ? _c("span", { staticClass: "sync-message" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$tr("syncFailed")) +
                              "\n        "
                          ),
                        ])
                      : _vm.neverSynced
                      ? _c("span", { staticClass: "sync-message" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$tr("neverSynced")) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm.facility.last_successful_sync
                      ? _c("span", { staticClass: "sync-message" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$tr("lastSync", {
                                  relativeTime: _vm.formattedTime(
                                    _vm.facility.last_successful_sync
                                  ),
                                })
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ],
              _c("KButton", {
                attrs: {
                  text: _vm.$tr("createSync"),
                  disabled: _vm.isSyncing || _vm.isDeleting,
                  appearance: "basic-link",
                },
                on: { click: _vm.manageSync },
              }),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }