var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.backdrop
        ? [
            _c("Backdrop", { staticClass: "snackbar-backdrop" }),
            _c("div", {
              attrs: { tabindex: "0" },
              on: { focus: _vm.trapFocus },
            }),
          ]
        : _vm._e(),
      _c(
        "transition",
        {
          attrs: { name: "snackbar" },
          on: { "leave-to": _vm.clearSnackbar, enter: _vm.handleOnEnter },
        },
        [
          _c("UiSnackbar", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isVisible,
                expression: "isVisible",
              },
            ],
            ref: "snackbar",
            staticClass: "snackbar",
            style: _vm.styles,
            attrs: {
              id: "coresnackbar",
              message: _vm.text,
              action: _vm.actionText,
              tabindex: "0",
            },
            on: { "action-click": _vm.handleActionClick },
            scopedSlots: _vm._u([
              {
                key: "inner-focus-trap",
                fn: function () {
                  return [
                    _c("div", {
                      attrs: { tabindex: "0" },
                      on: { focus: _vm.trapFocus },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }