var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { style: { display: "inline-block", maxWidth: "100%" } }, [
    _c(
      "span",
      _vm._b(
        {
          class: _vm.$computedClass(_vm.truncate),
          style: { display: "inline-block", maxWidth: "100%" },
        },
        "span",
        _vm.$attrs,
        false
      ),
      [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }