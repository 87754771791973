var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "backdrop", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "backdrop",
        class: { "has-transitions": _vm.transitions },
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }