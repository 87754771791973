var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "ScrollingHeader",
        { attrs: { scrollPosition: 0 } },
        [
          _c(
            "transition",
            { attrs: { mode: "out-in" } },
            [
              _vm.showAppBarsOnScroll
                ? _c("AppBar", {
                    ref: "appBar",
                    staticClass: "app-bar",
                    attrs: { title: _vm.title },
                    on: {
                      toggleSideNav: function ($event) {
                        _vm.navShown = !_vm.navShown
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "sub-nav",
                          fn: function () {
                            return [_vm._t("subNav")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.isLoading
            ? _c("KLinearLoader", {
                attrs: { type: "indeterminate", delay: false },
              })
            : _vm._e(),
          _c(
            "div",
            { attrs: { "aria-live": "polite" } },
            [
              _c("StorageNotification", {
                attrs: { showBanner: _vm.showStorageNotification },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main-wrapper", style: _vm.wrapperStyles },
        [_vm._t("default")],
        2
      ),
      _c(
        "transition",
        { attrs: { mode: "out-in" } },
        [
          _vm.showAppBarsOnScroll
            ? _c("SideNav", {
                ref: "sideNav",
                attrs: { navShown: _vm.navShown },
                on: {
                  toggleSideNav: function ($event) {
                    _vm.navShown = !_vm.navShown
                  },
                  shouldFocusFirstEl: function ($event) {
                    return _vm.findFirstEl()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }