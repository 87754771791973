var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: { title: _vm.registerFacility.$tr("registerFacility") },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("KButton", {
                staticClass: "kbuttons",
                attrs: {
                  text: _vm.coreString("cancelAction"),
                  appearance: "flat-button",
                },
                on: { click: _vm.closeModal },
              }),
              _vm.displaySkipOption
                ? _c("KButton", {
                    staticClass: "kbuttons",
                    attrs: {
                      text: _vm.skip.$tr("skipAction"),
                      appearance: "raised-button",
                    },
                    on: { click: _vm.skipRegister },
                  })
                : _vm._e(),
              _c("KButton", {
                attrs: {
                  text: _vm.coreString("continueAction"),
                  appearance: "raised-button",
                  primary: "",
                  disabled: _vm.submitting || !_vm.token,
                },
                on: { click: _vm.validateToken },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", [_vm._v(_vm._s(_vm.$tr("enterToken")))]),
      _c("KTextbox", {
        attrs: {
          type: "text",
          label: _vm.$tr("projectToken"),
          autofocus: true,
          invalid: _vm.invalid,
          invalidText: _vm.$tr("invalidToken"),
        },
        on: {
          input: function ($event) {
            _vm.invalid = false
          },
        },
        model: {
          value: _vm.token,
          callback: function ($$v) {
            _vm.token = $$v
          },
          expression: "token",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }