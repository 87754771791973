var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$slots.header
        ? _c(
            "header",
            { ref: "header", staticClass: "header", style: _vm.styles.header },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _vm.$slots.subheader
        ? _c(
            "section",
            { ref: "subheader", staticClass: "subheader" },
            [_vm._t("subheader")],
            2
          )
        : _vm._e(),
      _c(
        "KFixedGrid",
        { attrs: { numCols: "3" } },
        [
          _vm.$slots.aside
            ? _c(
                "KFixedGridItem",
                { ref: "aside", style: _vm.styles.aside, attrs: { span: "1" } },
                [_c("div", { staticClass: "aside" }, [_vm._t("aside")], 2)]
              )
            : _vm._e(),
          _c(
            "KFixedGridItem",
            {
              ref: "main",
              class: { "main-with-aside": _vm.$slots.aside },
              attrs: { span: _vm.$slots.aside ? 2 : 3 },
            },
            [_c("div", { staticClass: "main" }, [_vm._t("main")], 2)]
          ),
        ],
        1
      ),
      _vm.$slots.footer
        ? _c(
            "footer",
            { ref: "footer", staticClass: "footer", style: _vm.styles.footer },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }