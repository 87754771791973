var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "interaction-list" }, [
    _c(
      "div",
      { staticClass: "attempt-container" },
      _vm._l(_vm.interactions, function (interaction, index) {
        return _c("InteractionItem", {
          key: index,
          attrs: { selected: _vm.isSelected(index), interaction: interaction },
          nativeOn: {
            click: function ($event) {
              !_vm.isSelected(index)
                ? _vm.setCurrentInteractionIndex(index)
                : null
            },
          },
        })
      }),
      1
    ),
    _c("p", [_vm._v("\n    " + _vm._s(_vm.interactionsMessage) + "\n  ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }