var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "visuallyhidden", attrs: { id: "progress-bar-label" } },
      [_vm._v("\n    " + _vm._s(_vm.coreString("progressLabel")) + "\n  ")]
    ),
    _c(
      "div",
      {
        staticClass: "progress-bar-wrapper",
        style: { backgroundColor: _vm.$themePalette.grey.v_200 },
        attrs: {
          role: "progressbar",
          "aria-labelledby": "progress-bar-label",
          "aria-valuenow": _vm.percent,
          "aria-valuemin": "0",
          "aria-valuemax": "100",
        },
      },
      [
        _c("div", {
          staticClass: "progress-bar-complete",
          style: {
            width: _vm.percent + "%",
            backgroundColor: _vm.color || _vm.$themeTokens.primary,
          },
        }),
      ]
    ),
    _vm.showPercentage
      ? _c("div", { staticClass: "progress-bar-text" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$formatNumber(_vm.progress, { style: "percent" })) +
              "\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }