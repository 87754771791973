var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value > 0
    ? _c(
        "KLabeledIcon",
        {
          style: { color: _vm.$themeTokens.coachContent },
          scopedSlots: _vm._u(
            [
              {
                key: "icon",
                fn: function () {
                  return [
                    _c("KIcon", {
                      ref: "something",
                      attrs: {
                        icon: "coach",
                        color: _vm.$themeTokens.coachContent,
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            3635965754
          ),
        },
        [
          _vm.isTopic
            ? _c("span", { staticClass: "counter" }, [
                _vm._v(
                  "\n    " + _vm._s(_vm.$formatNumber(_vm.value)) + "\n  "
                ),
              ])
            : _vm._e(),
          _c(
            "KTooltip",
            {
              attrs: {
                reference: "something",
                placement: "bottom",
                refs: _vm.$refs,
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.titleText) + "\n  ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }