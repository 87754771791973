var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-error", attrs: { role: "alert" } },
    [
      _c("img", { attrs: { src: require("./app-error-bird.png") } }),
      _c("h1", [_vm._v("\n    " + _vm._s(_vm.headerText) + "\n  ")]),
      _vm._l(_vm.paragraphTexts, function (paragraph) {
        return _c("p", { key: paragraph }, [
          _vm._v("\n    " + _vm._s(paragraph) + "\n  "),
        ])
      }),
      _c(
        "p",
        [
          _c(
            "KButtonGroup",
            [
              _vm._t("buttons", function () {
                return [
                  !_vm.isPageNotFound
                    ? _c("KButton", {
                        attrs: {
                          text: _vm.coreString("refresh"),
                          primary: true,
                        },
                        on: { click: _vm.reloadPage },
                      })
                    : _vm._e(),
                  _c("KButton", {
                    attrs: {
                      primary: _vm.isPageNotFound,
                      appearance: "raised-button",
                      text: _vm.exitButtonLabel,
                    },
                    on: { click: _vm.handleClickBackToHome },
                  }),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      !_vm.isPageNotFound
        ? _c(
            "p",
            [
              _c("KButton", {
                attrs: {
                  appearance: "basic-link",
                  text: _vm.$tr("defaultErrorReportPrompt"),
                },
                on: {
                  click: function ($event) {
                    return _vm.revealDetailsModal()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showDetailsModal
        ? _c("ReportErrorModal", {
            attrs: { error: _vm.error },
            on: { cancel: _vm.hideDetailsModal },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }