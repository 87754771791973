var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "skip-nav-link" },
    [
      _c("KButton", {
        ref: "button",
        style: _vm.linkStyles,
        attrs: {
          appearance: "basic-link",
          text: _vm.$tr("skipToMainContentAction"),
        },
        on: { click: _vm.handleClickSkipLink },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }