var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: {
        title: _vm.getCommonSyncString("selectSourceTitle"),
        size: "medium",
        submitText: _vm.coreString("continueAction"),
        cancelText: _vm.coreString("cancelAction"),
        submitDisabled: _vm.submitDisabled,
      },
      on: {
        submit: function ($event) {
          return _vm.$emit("submit")
        },
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
      },
    },
    [
      _vm.showLoadingMessage
        ? _c("UiAlert", { attrs: { type: "info", dismissible: false } }, [
            _vm._v("\n    " + _vm._s(_vm.$tr("loadingMessage")) + "\n  "),
          ])
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }