var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: {
        size: "large",
        submitText: _vm.coreString("closeAction"),
        title: _vm.title,
      },
      on: { submit: _vm.submit },
    },
    [
      _c("p", [_vm._v(_vm._s(_vm.msg))]),
      _c(
        "p",
        [
          _vm.linkUrl
            ? _c("KExternalLink", {
                attrs: {
                  href: _vm.linkUrl,
                  text: _vm.linkText || _vm.linkUrl,
                  openInNewTab: true,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.isSuperuser
        ? _c("p", [_vm._v("\n    " + _vm._s(_vm.$tr("adminMessage")) + "\n  ")])
        : _vm._e(),
      _c(
        "p",
        [
          _c("KCheckbox", {
            attrs: {
              label: _vm.$tr("hideNotificationLabel"),
              checked: _vm.dontShowNotificationAgain,
            },
            on: {
              change: function ($event) {
                _vm.dontShowNotificationAgain = !_vm.dontShowNotificationAgain
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }