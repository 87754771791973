var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bottom",
      class: { "bottom-sm": _vm.windowIsSmall },
      style: { backgroundColor: _vm.$themeTokens.surface },
    },
    [_c("div", { staticClass: "inner-bottom" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }