var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { style: { display: "flex", alignItems: "center" } },
    [
      _vm.syncInProgress
        ? _c("KCircularLoader", {
            staticClass: "inline-loader",
            attrs: { size: 20, "data-test": "syncStatusSpinner" },
          })
        : _vm.syncIconDisplayMap
        ? _c("KIcon", {
            staticClass: "inline-icon",
            attrs: {
              icon: _vm.syncIconDisplayMap,
              "data-test": "syncStatusIcon",
            },
          })
        : _vm._e(),
      _c(
        "p",
        { class: _vm.displaySize, attrs: { "data-test": "syncStatusText" } },
        [_vm._v(_vm._s(_vm.syncTextDisplayMap))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }