var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canDownload
    ? _c(
        "KButton",
        {
          ref: "button",
          attrs: { hasDropdown: "", primary: _vm.$attrs.primary },
          scopedSlots: _vm._u(
            [
              {
                key: "menu",
                fn: function () {
                  return [
                    _c("KDropdownMenu", {
                      attrs: { options: _vm.fileOptions },
                      on: { select: _vm.download },
                    }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            1185209509
          ),
        },
        [_c("span", [_vm._v(_vm._s(_vm.$tr("downloadContent")))])]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }