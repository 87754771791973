var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentTryDefined
    ? _c("table", { staticClass: "scores" }, [
        !_vm.hideStatus
          ? _c("tr", { attrs: { "data-test": "try-status" } }, [
              _c("th", [
                _vm._v(
                  "\n      " + _vm._s(_vm.coreString("statusLabel")) + "\n    "
                ),
              ]),
              _c(
                "td",
                [
                  _c("ProgressIcon", {
                    staticClass: "svg-icon",
                    attrs: { progress: _vm.progress },
                  }),
                  _vm._v("\n      " + _vm._s(_vm.progressIconLabel) + "\n    "),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.masteryModel && !_vm.isSurvey
          ? _c("tr", { attrs: { "data-test": "try-mastery-model" } }, [
              _c("th", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.coreString("masteryModelLabel")) +
                    "\n    "
                ),
              ]),
              _c(
                "td",
                [
                  _c("MasteryModel", {
                    attrs: { masteryModel: _vm.masteryModel },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.isSurvey && _vm.correctDefined && !_vm.masteryModel
          ? _c("tr", { attrs: { "data-test": "try-score" } }, [
              _c("th", [
                _vm._v(
                  "\n      " + _vm._s(_vm.coreString("scoreLabel")) + "\n    "
                ),
              ]),
              _c("td", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$formatNumber(_vm.score, { style: "percent" })) +
                    "\n    "
                ),
              ]),
            ])
          : _vm._e(),
        !_vm.isSurvey && _vm.correctDefined && !_vm.masteryModel
          ? _c("tr", { attrs: { "data-test": "try-questions-correct" } }, [
              _c("th", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.coreString("questionsCorrectLabel")) +
                    "\n    "
                ),
              ]),
              _c("td", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.coreString("questionsCorrectValue", {
                        correct: _vm.currentTry.correct,
                        total: _vm.totalQuestions,
                      })
                    ) +
                    "\n      "
                ),
                _c("br"),
                _vm.questionsCorrectAnnotation
                  ? _c(
                      "span",
                      {
                        staticClass: "try-annotation",
                        style: { color: _vm.$themeTokens.annotation },
                      },
                      [_vm._v(_vm._s(_vm.questionsCorrectAnnotation))]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        !_vm.isSurvey && _vm.currentTry.time_spent
          ? _c("tr", { attrs: { "data-test": "try-time-spent" } }, [
              _c("th", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.coreString("timeSpentLabel")) +
                    "\n    "
                ),
              ]),
              _c(
                "td",
                [
                  _c("TimeDuration", {
                    attrs: { seconds: _vm.currentTry.time_spent },
                  }),
                  _c("br"),
                  _vm.timeSpentAnnotation
                    ? _c(
                        "span",
                        {
                          staticClass: "try-annotation",
                          style: { color: _vm.$themeTokens.annotation },
                        },
                        [_vm._v(_vm._s(_vm.timeSpentAnnotation))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("tr", { attrs: { "data-test": "try-attempted-ago" } }, [
          _c("th", [
            _vm._v("\n      " + _vm._s(_vm.$tr("attemptedLabel")) + "\n    "),
          ]),
          _c(
            "td",
            [
              _c("ElapsedTime", {
                attrs: {
                  date: new Date(
                    _vm.currentTry.completion_timestamp ||
                      _vm.currentTry.end_timestamp
                  ),
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }