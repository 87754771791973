var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.snackbarIsVisible
    ? _c("CoreSnackbar", {
        key: _vm.key,
        attrs: {
          text: _vm.snackbarOptions.text,
          actionText: _vm.snackbarOptions.actionText,
          backdrop: _vm.snackbarOptions.backdrop,
          autoDismiss: _vm.snackbarOptions.autoDismiss,
          duration: _vm.snackbarOptions.duration,
          bottomPosition: _vm.snackbarOptions.bottomPosition,
        },
        on: {
          actionClicked: function ($event) {
            return _vm.snackbarOptions.actionCallback()
          },
          hide: _vm.hideCallback,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }