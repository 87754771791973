var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "pos-rel" },
    [
      _c(
        "span",
        { ref: "icon" },
        [
          _vm.hasSuperAdminPermission
            ? _c("KIcon", {
                staticClass: "icon super-admin",
                style: _vm.iconStyle,
                attrs: { icon: "permission" },
              })
            : _vm.hasLimitedPermissions
            ? _c("KIcon", {
                staticClass: "icon some-permissions",
                style: _vm.iconStyle,
                attrs: { icon: "permission" },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "KTooltip",
        { attrs: { reference: "icon", refs: _vm.$refs } },
        [
          _vm.hasSuperAdminPermission
            ? _c("UserTypeDisplay", {
                attrs: { userType: _vm.UserKinds.SUPERUSER },
              })
            : _vm.hasLimitedPermissions
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$tr("limitedPermissionsTooltip")) +
                    "\n    "
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }